<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">ROTASI</p>
          <router-link
            v-if="isAdmin"
            :to="{ name: 'stase-add' }"
            class="card-header-icon has-text-light"
          >
            <b-icon class="has-text-light" size="is-small" icon="plus" />
          </router-link>
        </header>
        <div class="card-content table-card-content">
          <b-field position="is-right" class="filter-field" grouped group-multiline>
            <generic-filter-select
              class="filter3"
              apiPath="/stase/tahun-options/"
              field="tahun"
              v-model="filterMap.tahun"
            ></generic-filter-select>
            
            <generic-filter-select
              class="filter3"
              apiPath="/matakuliah/options/"
              field="Mata Kuliah"
              v-model="filterMap.mata_kuliah"
            ></generic-filter-select>

            <cari-input class="filter3" v-model="filterMap.cari"></cari-input>
            <filter-button @apply-filter="applyFilter"></filter-button>
          </b-field>

          <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
          <div class="box-container">
            <small v-if="staseList.length > 0" class="page-info is-pulled-right"
              ><strong>{{ pagination.startRowNum }}</strong> -
              <strong>{{ pagination.startRowNum + staseList.length - 1 }}</strong>
              dari <strong>{{ pagination.total }}</strong></small
            >
            <div class="cleardiv"></div>
            <box
              v-for="stase in staseList"
              :key="stase.id"
              :title="stase.mata_kuliah"
              :subtitle1="`${stase.mulai } - ${ stase.hingga }`"
              :subtitle2="stase.preceptor"
              :detailUrl="{ name: 'stase-detail', params: { id: stase.id } }"
            ></box>
            <box v-if="staseList.length == 0">
              <template v-slot:content>
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>Tidak ada data.</p>
                  </div>
                </section>
              </template>
            </box>
          </div>

          <!-- <b-table
            bordered
            striped
            hoverable
            :loading="loading"
            :data="staseList"
            :mobile-cards="false"
          >
            <b-table-column
              field="no"
              label="No"
              v-slot="props"
            >{{ pagination.startRowNum + props.index }}</b-table-column>
            <b-table-column field="departemen" label="Departemen" v-slot="props">
              <small>{{ props.row.mulai }} - {{ props.row.hingga }}</small>
              <br />
              <router-link
                :to="{ name: 'stase-detail', params: {id: props.row.id} }"
              >{{ props.row.departemen }}</router-link>
              <br />
            </b-table-column>
            <b-table-column
              field="koordinator"
              label="Koordinator"
              v-slot="props"
            >{{ props.row.preceptor }}</b-table-column>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>Tidak ada data.</p>
                </div>
              </section>
            </template>
          </b-table>-->

          <b-pagination
            v-if="pagination.showPagination"
            :total="pagination.total"
            :per-page="pagination.limit"
            :current.sync="pagination.page"
            order="is-centered"
            icon-pack="fas"
            @change="pageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import fetchActivatedMixin from "@/apps/core/mixins/fetchActivatedMixin";
import paginationMixin from "@/apps/core/mixins/paginationMixin";
import StaseList from "../models/staseList.js";

export default {
  name: "StaseList",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    CariInput: () => import("@/apps/core/components/CariInput.vue"),
    FilterButton: () => import("@/apps/core/components/FilterButton.vue"),
    GenericFilterSelect: () =>
      import("@/apps/core/components/GenericFilterSelect.vue"),
  },
  data() {
    this.staseListMdl = new StaseList();
    this.listMdl = this.staseListMdl; // bikin alias
    return this.staseListMdl.getObservables();
  },
  computed: {
    ...mapGetters("accounts", ["isAdmin"]),
  },
  mixins: [onlineCheckMixin, fetchActivatedMixin, paginationMixin],
};
</script>


<style scoped lang="scss">
@import "@/apps/core/assets/scss/listview.scss";

.ol-style {
  margin-left: 15px;
  padding: 5px;
}

/* ::v-deep .kel-filter {
  width: 12rem;
} */
</style>
